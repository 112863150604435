<template>
  <div class="mx-auto" style="width: fit-content">
    <div
      class="row d-flex justify-content-center p-2"
      style="max-width: 370px; height: 100vh"
    >
      <div class="col-lg-12 row align-self-start mt-2">
        <div class="col-lg-12">
          <img
            alt="blend box logo"
            class="img-fluid logo"
            src="blendBoxLogo.svg"
            width="128px"
          />
        </div>
        <div class="col-lg-12">
          <img
            alt="blend box logo"
            class="img-fluid logo"
            src="../../assets/images/login/loginImg.png"
            width="352px"
          />
        </div>
      </div>

      <div class="col-lg-12 align-self-center">
        <label class="form-control-label">Enter your OTP</label>
        <div id="otp" class="d-flex justify-content-between">
          <input
            v-model="pin1"
            maxlength="1"
            type="text"
            v-on:keyup="keyMonitor"
          />
          <input
            v-model="pin2"
            maxlength="1"
            type="text"
            v-on:keyup="keyMonitor"
          />
          <input
            v-model="pin3"
            maxlength="1"
            type="text"
            v-on:keyup="keyMonitor"
          />
          <input
            v-model="pin4"
            maxlength="1"
            type="text"
            v-on:keyup="keyMonitor"
          />
        </div>
      </div>
      <div class="col-lg-12 align-self-start">
        <button
          id="sub_btn"
          :class="{ game_btn: true, activeBtn: isChanged }"
          :disabled="btnDisabled"
          class="w-100 d-block main-button-orange"
          @click="verify()"
        >
          {{ btnDisabled ? "Loading ..." : "Subscribe" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/api"
import AlertServie from "../../services/errors"
import Vue from "vue"

export default {
  data() {
    return {
      prefixPHone: "+96475",
      pin1: "",
      pin2: "",
      pin3: "",
      pin4: "",
      phone: "+96475",
      isChanged: false,
      btnDisabled: false,
      isDesktopSize: false
    }
  },
  methods: {
    checkScreenSize() {
      // Update isDesktopSize based on screen width
      this.isDesktopSize = window.innerWidth >= 768 // Adjust the breakpoint as needed
    },

    keyMonitor(event) {
      if (event.key === "Backspace" || event.key === "Delete") {
        event.target.previousElementSibling.focus()
      } else {
        this.isChanged = true
        event.target.nextElementSibling.focus()
      }
    },
    async verify() {
      this.btnDisabled = true
      const otp = this.pin1 + this.pin2 + this.pin3 + this.pin4
      const phoneNumber = this.$store.state.phoneNumber
      const ts = this.$store.state.ts
      const ti = this.$store.state.ti
      const response = await ApiService.verify(phoneNumber, otp, ts, ti)
      const self = this

      if (response && response.status === 200) {
        if (response.data.data !== "") {
          localStorage.setItem("BlendBox", response.data.access_token)
          this.$store.commit("setPoints", response.data.user.points)
          ApiService.headers = {
            Authorization: "Bearer " + response.data.access_token,
            lang: localStorage.getItem("lang")
          }
          self.$router.push("/")
        } else {
          AlertServie.info(response.data.msg)

          if (response.status === 200) {
            this.checkUserStatusInterval = setInterval(async function () {
              const response = await ApiService.checkUserStatus(phoneNumber)
              if (response && response.status === 200) {
                if (response.data.status === 2 || response.data.status === 1) {
                  localStorage.setItem("BlendBox", response.data.access_token)
                  clearInterval(self.checkUserStatusInterval)
                  ApiService.headers = {
                    Authorization: "Bearer " + response.data.access_token,
                    lang: localStorage.getItem("lang")
                  }
                  self.$router.push("/")
                  Vue.$forceUpdate()
                } else {
                  if (response.data.status === 3) {
                    clearInterval(self.checkUserStatusInterval)
                  }
                  AlertServie.info(response.data.msg)
                  this.disabled = false
                  this.btnDisabled = false
                }
              } else {
                clearInterval(self.checkUserStatusInterval)
                AlertServie.info(response.data.msg)
                this.btnDisabled = false
                this.disabled = false
              }
            }, 5000)
          }
        }
      } else {
        clearInterval(self.checkUserStatusInterval)
        this.disabled = false
        this.btnDisabled = false
        AlertServie.errorMessage(response.data.msg)
      }
    }
  },
  beforeDestroy() {
    // Clean up by removing the window resize event listener
    window.removeEventListener("resize", this.checkScreenSize)
  },
  mounted() {
    // Check screen size on component mount and update isDesktopSize
    this.checkScreenSize()
    // Listen for window resize events to update isDesktopSize
    window.addEventListener("resize", this.checkScreenSize)
  },
  watch: {
    btnDisabled(val) {
      if (val) {
        document.getElementById("sub_btn").classList.add("activeBtn")
        document.getElementById("sub_btn").classList.remove("notActiveBtn")
      } else {
        document.getElementById("sub_btn").classList.remove("activeBtn")
        document.getElementById("sub_btn").classList.add("notActiveBtn")
      }
    }
  }
}
</script>

<style scoped>
input[type="text"] {
  width: 40px;
  height: 40px;
  border: 2px solid transparent; /* Transparent border */
  text-align: center;
  font-size: 24px;
  background-color: #b3b3b3;
  border-radius: 5px;
  position: relative; /* Positioning for pseudo-element */
  z-index: 1;
}

input:focus {
  outline: none;
  box-shadow: 0 0 0;
}

.form-control-label {
  font-size: 14px;
  color: var(--iq-label-grey);
  font-weight: bold;
  letter-spacing: 1px;
}
</style>
